/* CSS Document */

html, body {
  position: relative;
  min-height: 100%;
 	height: 100%;
	font-size:12px; 
	background-image: url(../img/background-image.png);
/*	background-color:$base-color; */
	color: $base-font-color;
}
h1,h2,h3,h4,h5,h6 { font-weight:bold; margin-bottom:10px; font-size:16px; }

body {
  /* Margin bottom by footer height */
 
}
body > .container {
  padding: 60px 15px 0;
}
.footer {
	position: absolute;
	bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  background-color: $base2-color;
  color: $base2-font-color;
}
.footer > .container {
	padding: 20px 0;
	}
.navbar-brand {
	padding: 8px;
	}
	
.well {
	background-color:$base-color;
	font-size:14px;
	margin: 0 auto;
	border:0;
	margin-top:50px;
	}
.well h1 {
	font-size:24px;
	color:$secondary-color;
	}
.well-home {
	width:60%;
	}
.well a {
	color: $secondary-color;
	}